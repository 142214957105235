body {
  background-color: #F0F0F5;
}

h1 {
  font-size: 36pt;
  font-weight: 500;
  margin-bottom: 0;
}

h2 {
  font-weight: 500;
}

span {
  padding: 0.2em;
}

.app {
  font-family: sans-serif;
  text-align: center;
}

.app-header {
  align-items: center;
  color: #333333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 15vh;
}

.button-row {
  display: flex;
}

.row-item {
  padding-left: 1em;
  padding-right: 1em;
}

.row-btn {
  background-color: inherit;
  border: 0;
  color: #45c;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5em;
  margin-bottom: 0;
  padding: 0.3em 1em 0 1em;
}
.row-btn:hover {
  color: #34b;
  cursor: pointer;
}

.row-btn-selected {
  color: #abf;
  background-color: #45454b;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.row-btn-selected:hover {
  color: #78d;
}

.thumb {
  height: 250px;
  width: 250px;
}
.thumb:hover {
  border: 5px solid #203081;
  margin: -5px;
}

.well {
  background-color: #45454b;
  padding: 2em;
}

.well-item-dark {
  color: #eeeeee;
  padding-left: 1em;
  padding-right: 1em;
}

.well-link-dark {
  color: #abf;
  font-weight: 700;
  cursor: pointer;
  padding: 0.3em 1em 0 1em;
}
.well-link-dark:hover {
  color: #78d;
}
